/** @type {import('tailwindcss').Config} */
module.exports = {
  content: [
    './layout/**/*.liquid',
    './sections/**/*.liquid',
    './snippets/**/*.liquid',
    './templates/**/*.liquid',
    './src/scripts/**/*.{js,jsx}',
    './assets/**/*.js',
  ],
  safelist: [
    'md:col-span-1',
    'md:col-span-2',
    'md:col-span-3',
    'md:col-span-4',
    'md:col-span-5',
    'md:col-span-6',
    'md:col-span-7',
    'md:col-span-8',
    'md:col-span-9',
    'md:col-span-10',
    'md:col-span-11',
    'md:col-span-12',
    'lg:col-span-1',
    'lg:col-span-2',
    'lg:col-span-3',
    'lg:col-span-4',
    'lg:col-span-5',
    'lg:col-span-6',
    'lg:col-span-7',
    'lg:col-span-8',
    'lg:col-span-9',
    'lg:col-span-10',
    'lg:col-span-11',
    'lg:col-span-12',
  ],
  theme: {
    colors: {
      primary: {
        1: '#131317',
        2: '#D58C20',
        3: '#F4F5F5',
        4: '#9ED4E1',
      },
      grey: {
        1: '#292929',
        2: '#434343',
        3: '#6C6C6C',
        4: '#f8f8f8',
      },
      transparent: 'transparent',
      black: 'black',
      white: 'white',
      current: 'currentColor',
      success: '#4cc258',
      warning: '#f48e2f',
      error: '#C63D45',
    },
    fontFamily: {
      condensed:
        '"Roboto Condensed", Seravek, "Gill Sans Nova", Ubuntu, Calibri, "DejaVu Sans", source-sans-pro, sans-serif',
      mono: '"Roboto Mono", ui-monospace, "Cascadia Code", "Source Code Pro", Menlo, Consolas, "DejaVu Sans Mono", monospace',
      sans: '"Roboto Flex", system-ui, sans-serif',
      heading: 'Antonio, system-ui, sans-serif',
    },
    screens: {
      xs: '460px',
      sm: '576px',
      md: '820px',
      lg: '1024px',
      xl: '1366px',
    },
    extend: {
      borderRadius: {
        'product-item': '1.25rem',
      },
      aspectRatio: {
        'product-item': '1 / 1',
        fullscreen: '4 / 3',
        tall: '5 / 7',
      },
      zIndex: {
        '-1': '-1',
      },
      flex: {
        2: '2 2 0%',
      },
    },
  },
  plugins: [require('@tailwindcss/forms')],
};
