import { formatMoney } from '@shopify/theme-currency';
import { getTranslation } from '../../helpers';
import { h } from 'preact';
import { useCartContext } from '../context/CartContext';

const CartTotals = () => {
  const {
    cart: { cart_level_discount_applications, total_price },
  } = useCartContext();

  return (
    <div class="flex flex-col gap-2">
      <div class="flex justify-between">
        <p class="type-heading-5">{getTranslation('cart.subtotal')}</p>
        <p class="type-text-6">{formatMoney(total_price, theme.moneyFormat)}</p>
      </div>

      <div class="type-text-4 flex items-center justify-between">
        <p>{getTranslation('mini_cart.shipping')}</p>
        <p>{getTranslation('mini_cart.shipping_at_checkout')}</p>
      </div>

      {/* Discounts */}
      <div class="flex flex-col items-end space-y-2">
        {cart_level_discount_applications.length > 0 &&
          cart_level_discount_applications.map(item => (
            <div class="type-text-5">
              {item.title} (-
              {formatMoney(item.total_allocated_amount, theme.moneyFormat)})
            </div>
          ))}
      </div>
    </div>
  );
};

export default CartTotals;
