import Splide from '@splidejs/splide';

const selectors = {
  productImage: '[data-product-image]',
  mainCarousel: '[data-image-carousel]',
  thumbnailCarousel: '[data-thumbnail-carousel]',
};

/**
 * Product image gallery on the PDP
 *
 * @export
 * @class ProductImages
 */
export default class ProductImages {
  constructor(el) {
    this.mainCarousel = el.querySelector(selectors.mainCarousel);
    this.thumbnailCarousel = el.querySelector(selectors.thumbnailCarousel);
    this.navItems = this.thumbnailCarousel.querySelectorAll(
      selectors.productImage,
    );
    this._initCarousel();
  }

  /**
   * Initialize primary image carousel
   */
  _initCarousel() {
    this.mainCarousel = new Splide(this.mainCarousel, {
      perPage: 1,
      pagination: false,
      arrows: true,
      mediaQuery: 'min',
    });

    this.thumbnailCarousel = new Splide(this.thumbnailCarousel, {
      fixedWidth: 80,
      fixedHeight: 80,
      gap: 10,
      pagination: false,
      arrows: false,
      isNavigation: true,
      mediaQuery: 'min',
    });

    this.mainCarousel.sync(this.thumbnailCarousel);
    this.mainCarousel.mount();
    this.thumbnailCarousel.mount();
  }

  /**
   * Handler for swapping images when the variant changes
   *
   * @param {Object} variant The currently selected variant
   * @public
   */
  change(variant) {
    this.navItems.forEach((element, index) => {
      const { imageVariants } = element.dataset;
      // Bail if array of variants for this image
      // doesn't contain currently selected variant id
      if (!imageVariants.split(',').includes(String(variant.id))) {
        return;
      }

      this.mainCarousel.go(index);
    });
  }
}
