import initCarousel from '../helpers/carousel';
import { unitlessBreakpoints } from '../helpers/breakpoints';

export default class DynamicCardFeed {
  constructor(section) {
    this.el = section.el;
    this.section = section;

    initCarousel(this.el, {
      perPage: 1,
      arrows: false,
      padding: {
        right: 100,
      },
      breakpoints: {
        [unitlessBreakpoints.xs]: {
          perPage: 2,
          padding: {
            right: 40,
          },
        },
        [unitlessBreakpoints.sm]: {
          perPage: 2,
          padding: {
            right: 80,
          },
        },
        [unitlessBreakpoints.md]: {
          perPage: 3,
        },
        [unitlessBreakpoints.lg]: {
          destroy: true,
        },
      },
    });
  }
}
