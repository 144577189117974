/**
 * Scroll direction detection
 */
class Scroll {
  /**
   * @param {Function} onPageScroll - callback function for scroll direction
   * @returns {String} the direction of scroll ("up" or "down")
   */
  constructor(onPageScroll, rootEl) {
    this.onPageScroll = onPageScroll;
    this.lastScrollTop = window.pageYOffset;
    this.headerHeight = rootEl.clientHeight;

    document.addEventListener('scroll', this.onScroll);
  }

  onScroll = () => {
    const scrollTop = window.pageYOffset || document.documentElement.scrollTop;

    if (
      scrollTop < this.headerHeight * 3 &&
      this.lastScrollTop < this.headerHeight * 3
    ) {
      this.lastScrollTop = scrollTop;
      return;
    } else if (
      scrollTop < this.headerHeight * 3 &&
      this.lastScrollTop > this.headerHeight * 3
    ) {
      this.onPageScroll('nearTop');
    } else if (scrollTop < this.headerHeight && this.lastScrollTop > 0) {
      this.onPageScroll('top');
    } else if (scrollTop < this.lastScrollTop) {
      this.onPageScroll('up');
    } else if (scrollTop > this.lastScrollTop) {
      this.onPageScroll('down');
    }

    this.lastScrollTop = scrollTop;
  };
}

export default Scroll;
