import CartCrossSellProduct from './CartCrossSellProduct';
import { getTranslation } from '../../helpers';
import { h } from 'preact';
import { useCartContext } from '../context/CartContext';

import { Splide, SplideSlide, SplideTrack } from '@splidejs/react-splide';
import { useEffect, useState } from 'preact/hooks';

const CartCrossSell = () => {
  const { cart, isMiniCartOpen, miniCartData } = useCartContext();
  const [crossSellItems, setCrossSellItems] = useState([]);

  // Function to remove objects with duplicate IDs
  const removeDuplicates = arr => {
    const uniqueIds = [];

    return arr.filter(item => {
      if (uniqueIds.indexOf(item.id) === -1) {
        uniqueIds.push(item.id);
        return true;
      }
      return false;
    });
  };

  useEffect(() => {
    if (isMiniCartOpen && cart && cart.items.length > 0) {
      setCrossSellItems([]);
      cart.items.forEach(item => {
        fetch(
          `${Shopify.routes.root}recommendations/products.json?product_id=${
            item.product_id
          }&limit=${miniCartData.settings.limit || 3}&intent=related`,
        )
          .then(response => {
            return response.json();
          })
          .then(({ products }) => {
            if (products.length > 0) {
              setCrossSellItems(prev =>
                removeDuplicates([...prev, ...products]),
              );
            }
          });
      });
    }
  }, [cart.items, isMiniCartOpen]);

  return crossSellItems.length > 0 ? (
    <div class="relative mt-4 overflow-hidden bg-primary-3 px-4 py-6 text-primary-1 md:px-6">
      <h5 class="type-heading-5 mb-4">
        {getTranslation('mini_cart.you_might_also_like')}
      </h5>

      <Splide
        hasTrack={false}
        options={{
          autoWidth: true,
          pagination: false,
          arrows: false,
        }}
      >
        <SplideTrack className="overflow-visible">
          {crossSellItems.map(item => (
            <SplideSlide key={item.product_id}>
              <CartCrossSellProduct product={item} />
            </SplideSlide>
          ))}
        </SplideTrack>
      </Splide>
    </div>
  ) : null;
};

export default CartCrossSell;
