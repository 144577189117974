import initCarousel from '../helpers/carousel';
import { unitlessBreakpoints } from '../helpers/breakpoints';

export default class DynamicLogoCarousel {
  constructor(section) {
    this.carousel = initCarousel(section.el, {
      perPage: 1,
      perMove: 1,
      autoWidth: true,
      type: 'loop',
      padding: '20%',
      arrows: true,
      gap: '1.25rem',
      breakpoints: {
        [unitlessBreakpoints.md]: {
          perPage: 1,
        },
        [unitlessBreakpoints.lg]: {
          gap: '2.5rem',
          perPage: 1,
        },
      },
    });
  }
}
