import MobileSearch from '../components/search/MobileSearch';
import Scroll from '../helpers/scroll';
import Search from '../components/search/Search';
import { pixelBreakpoints } from '../helpers';
import scrollLock from 'scroll-lock';

const selectors = {
  headerNavigation: '[data-header-navigation]',
  headerMegaDetails: '[data-header-mega-details]',
  megaBackdrop: '[data-mega-backdrop]',
  megaBackButton: '[data-submenu-back]',
  navHamburger: '[data-nav-hamburger]',
  navClose: '[data-nav-close]',
  announcementBar: '[data-announcement-bar]',
  headerLogo: '[data-header-logo]',
  headerHomeLogo: '[data-header-home-logo]',
  navItemRoot: '[data-nav-item-root]',
  navList: '[data-nav-list]',
  desktopNavRow: '[data-desktop-nav-row]',
};

export default class MainHeader {
  constructor(section) {
    this.section = section;
    this.headerLogo = section.el.querySelector(selectors.headerLogo);
    this.headerHomeLogo = section.el.querySelector(selectors.headerHomeLogo);

    this.isHomePage = false;
    if (document.body.classList.contains('template-index')) {
      this.isHomePage = true;
    }

    this.listener = new Scroll(this.onScroll, this.section.el);
    this.section.el.className +=
      ' top-0 z-50 bg-primary-1 transition-transform';

    new Search(section);
    new MobileSearch(section);

    this.initNavigation();
    this.initMobileNavigation();
  }

  onScroll = direction => {
    if (direction === 'top') {
      this.showHomeLogo();
      this.showMenuAtTop();
    } else if (direction === 'nearTop') {
      this.hideMenuNearTop();
      this.showHomeLogo();
    } else if (direction === 'up') {
      this.showMenu();
    } else if (direction === 'down') {
      this.hideMenu();
      this.hideHomeLogo();
    }
  };

  showHomeLogo = () => {
    if (this.isHomePage) {
      this.headerLogo.classList.add('hidden');
      this.headerHomeLogo.classList.remove('hidden');
    }
  };

  hideHomeLogo = () => {
    if (this.isHomePage) {
      this.headerLogo.classList.remove('hidden');
      this.headerHomeLogo.classList.add('hidden');
    }
  };

  showMenuAtTop = () => {
    this.section.el.classList.remove('-translate-y-full');
    this.section.el.classList.remove('sticky');
  };

  showMenu = () => {
    this.section.el.classList.remove('-translate-y-full');
    this.section.el.classList.add('sticky');
  };

  hideMenuNearTop = () => {
    this.section.el.classList.add('-translate-y-full');
    setTimeout(() => {
      this.section.el.classList.remove('sticky');
      this.section.el.classList.remove('-translate-y-full');
    }, 250);
  };

  hideMenu = () => {
    this.section.el.classList.add('-translate-y-full');
  };

  initNavigation = () => {
    this.clickOutsideNavigationItems();

    const megaDetails = this.section.el.querySelectorAll(
      selectors.headerMegaDetails,
    );
    // Lock scrolling when mega menu opens
    megaDetails.forEach(link => {
      link.addEventListener('toggle', () => {
        if (link.open) {
          scrollLock.disablePageScroll();
          this.hideHomeLogo();
        } else {
          scrollLock.enablePageScroll();
        }
      });
    });
  };

  clickOutsideNavigationItems = () => {
    const megaDetails = this.section.el.querySelectorAll(
      selectors.headerMegaDetails,
    );
    const megaBackdrops = this.section.el.querySelectorAll(
      selectors.megaBackdrop,
    );

    document.addEventListener('click', e => {
      megaDetails.forEach(link => {
        if (e.target !== link && !link.contains(e.target)) {
          link.removeAttribute('open');
        }
      });
      megaBackdrops.forEach(back => {
        if (e.target === back) {
          const parentDetail = back.closest('details');
          parentDetail.removeAttribute('open');
          this.closeMobileNav();
        }
      });
    });
  };

  initMobileNavigation = () => {
    const megaDetails = this.section.el.querySelectorAll(
      selectors.headerMegaDetails,
    );
    const megaBackButtons = this.section.el.querySelectorAll(
      selectors.megaBackButton,
    );

    this.initOpenMobileNav();
    this.initCloseMobileNav();

    // Set subnav heights
    megaDetails.forEach(link => {
      const summary = link.querySelector('summary');
      summary.addEventListener('click', e => {
        if (!window.matchMedia(`(min-width: ${pixelBreakpoints.md})`).matches) {
          e.stopPropagation();

          const navRoot = link.closest(selectors.navItemRoot);
          const navList = link.querySelector(selectors.navList);
          if (navRoot && navList) {
            setTimeout(() => {
              navRoot.style.height = `${navList.offsetHeight}px`;
            }, 0);
          }
          const megaBackdrop = link.querySelector(selectors.megaBackdrop);
          const totalHeaderHeight = this.getHeaderHeight();
          megaBackdrop.style.height = `${
            window.innerHeight - totalHeaderHeight
          }px`;
        }
      });
    });

    // Set subnav heights
    megaBackButtons.forEach(button => {
      button.addEventListener('click', () => {
        const parentDetail = button.closest('details');
        parentDetail.removeAttribute('open');
        const navRoot = parentDetail.closest(selectors.navItemRoot);
        const navList = parentDetail.closest(selectors.navList);
        if (navRoot && navList) {
          navRoot.style.height = `${navList.offsetHeight}px`;
        }
      });
    });
  };

  initOpenMobileNav = () => {
    const headerNavigation = this.section.el.querySelector(
      selectors.headerNavigation,
    );
    const navHamburger = this.section.el.querySelector(selectors.navHamburger);
    const navClose = this.section.el.querySelector(selectors.navClose);
    navHamburger.addEventListener('click', () => {
      navHamburger.classList.add('hidden');
      navClose.classList.remove('hidden');
      headerNavigation.classList.remove('hidden');
      headerNavigation.classList.add('flex');

      this.hideHomeLogo();

      if (!window.matchMedia(`(min-width: ${pixelBreakpoints.md})`).matches) {
        scrollLock.disablePageScroll();
        const totalHeaderHeight = this.getHeaderHeight();
        headerNavigation.style.height = `calc(100vh - ${totalHeaderHeight - 2}px)`;
      } else {
        headerNavigation.style.height = 'auto';
      }
    });
  };

  initCloseMobileNav = () => {
    const navClose = this.section.el.querySelector(selectors.navClose);

    navClose.addEventListener('click', () => {
      this.closeMobileNav();
    });
  };

  closeMobileNav = () => {
    const headerNavigation = this.section.el.querySelector(
      selectors.headerNavigation,
    );
    const navHamburger = this.section.el.querySelector(selectors.navHamburger);
    const navRoots = this.section.el.querySelectorAll(selectors.navItemRoot);
    const navClose = this.section.el.querySelector(selectors.navClose);

    navHamburger.classList.remove('hidden');
    navClose.classList.add('hidden');
    headerNavigation.classList.add('hidden');
    headerNavigation.classList.remove('flex');
    navRoots.forEach(navRoot => {
      navRoot.style.height = 'auto';
    });
    scrollLock.enablePageScroll();
  };

  getHeaderHeight = () => {
    const announcementBar = document.querySelector(selectors.announcementBar);
    const headerHeight = this.section.el.offsetHeight;
    let announcementBarHeight = 0;
    if (announcementBar) {
      const announcementElementHeight = announcementBar.offsetHeight;
      const announcementElementShowing =
        announcementElementHeight - document.documentElement.scrollTop;
      announcementBarHeight =
        announcementElementShowing < 0 ? 0 : announcementElementShowing;
    }

    return headerHeight + announcementBarHeight;
  };
}
