const BikeIcon = ({ className }) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    viewBox="0 0 24 25"
    fill="none"
    className={className}
  >
    <path
      d="M19.5 10.52c-.54 0-1.08.1-1.58.3l-2.36-4.24H18c.2 0 .4.08.53.23.14.15.22.35.22.55 0 .21.08.41.22.56.14.15.33.23.53.23s.39-.08.53-.23a.81.81 0 0 0 .22-.56c0-.62-.24-1.22-.66-1.67-.42-.44-1-.69-1.59-.69h-3.74a.72.72 0 0 0-.38.1.77.77 0 0 0-.27.3.82.82 0 0 0 0 .78l1.1 1.97H9.3L7.8 5.4a.77.77 0 0 0-.28-.28.72.72 0 0 0-.37-.11H4.52c-.2 0-.39.08-.53.23a.81.81 0 0 0-.22.56c0 .2.08.4.22.56.14.14.33.23.53.23h2.2l1.27 2.31-1.47 2.13a4.3 4.3 0 0 0-3.26-.32 4.54 4.54 0 0 0-2.6 2.09 4.95 4.95 0 0 0 1.19 6.3 4.34 4.34 0 0 0 6.06-.73 4.9 4.9 0 0 0-.18-6.41l1.09-1.57 2.92 5.26a.78.78 0 0 0 .45.4.71.71 0 0 0 .6-.07.76.76 0 0 0 .35-.5.83.83 0 0 0-.1-.62l-2.85-5.12h5.38l1.05 1.89a4.94 4.94 0 0 0-1.01 5.99c.53.96 1.36 1.7 2.35 2.08 1 .38 2.09.38 3.08 0 1-.38 1.83-1.11 2.36-2.08a4.94 4.94 0 0 0-1-5.99 4.36 4.36 0 0 0-2.9-1.1ZM7.52 15.25c0 .67-.21 1.33-.59 1.87a3 3 0 0 1-1.53 1.14c-.61.2-1.27.19-1.87-.04a3.02 3.02 0 0 1-1.49-1.2 3.27 3.27 0 0 1 .14-3.74c.4-.53.95-.9 1.57-1.08a2.86 2.86 0 0 1 1.87.12l-1.7 2.46a.8.8 0 0 0-.16.6.82.82 0 0 0 .3.52.73.73 0 0 0 .57.14.73.73 0 0 0 .5-.33l1.7-2.46c.44.56.69 1.27.69 2ZM19.5 18.4a2.9 2.9 0 0 1-1.67-.53c-.49-.35-.87-.85-1.1-1.42A3.3 3.3 0 0 1 17.4 13l1.46 2.63a.78.78 0 0 0 .46.4.71.71 0 0 0 .59-.07.76.76 0 0 0 .35-.5.83.83 0 0 0-.1-.62l-1.46-2.64a2.85 2.85 0 0 1 2.45.4c.36.24.66.57.9.95a3.26 3.26 0 0 1-1.3 4.56c-.39.18-.81.28-1.24.28Z"
      fill="currentColor"
    />
  </svg>
);

export default BikeIcon;
