import Accordion from '../components/Accordion';

const selectors = {
  productDetailsTab: '[data-product-details-tab]',
  productDetailsContent: '[data-product-details-content]',
  rawProductDescription: '[data-raw-product-description]',
  rawProductSpecs: '[data-raw-product-specs]',
};

const classes = {
  tabInactive: ['border-transparent'],
  tabActive: ['text-primary-1', 'border-primary-1'],
  contentInactive: 'hidden',
  firstLoadContent: 'first:block',
  firstLoadTab: 'first:border-primary-1',
};

export default class ProductDetails {
  constructor(section) {
    this.el = section.el;
    this.initTabs();
    this.activateTab(0);
    this._initAccordion(section);
    this._splitProductDescription();
  }

  initTabs() {
    this.productDetailsTab = this.el.querySelectorAll(
      selectors.productDetailsTab,
    );
    this.productDetailsContent = this.el.querySelectorAll(
      selectors.productDetailsContent,
    );

    this.productDetailsTab.forEach((tab, index) => {
      tab.addEventListener('click', () => {
        this.deactivateAllTabs();
        this.activateTab(index);
      });
    });
  }

  _initAccordion(section) {
    const el = section.el.querySelector('[data-faq-block]');

    if (!el) {
      return;
    }

    new Accordion({
      el: el,
      triggerClass: 'faq-trigger',
      contentClass: 'faq-content',
      closeOnClickOutside: false,
    });
  }

  activateTab(index) {
    this.productDetailsTab[index].classList.add(...classes.tabActive);
    this.productDetailsTab[index].classList.remove(...classes.tabInactive);
    this.productDetailsContent[index].classList.remove(classes.contentInactive);
  }

  deactivateAllTabs() {
    this.productDetailsContent.forEach(content => {
      content.classList.remove(classes.firstLoadContent);
      content.classList.add(classes.contentInactive);
    });
    this.productDetailsTab.forEach(tab => {
      tab.classList.remove(classes.firstLoadTab);
      tab.classList.add(...classes.tabInactive);
      tab.classList.remove(...classes.tabActive);
    });
  }

  _splitProductDescription() {
    let desc = this.el.querySelector(selectors.rawProductDescription);

    if (!desc) {
      return;
    }

    while (desc.children.length === 1) {
      desc = desc.firstElementChild;
    }

    const parsed = this._parseSpecs(desc);
    if (parsed.length) {
      const landingSpecs = document.querySelector(selectors.rawProductSpecs);
      parsed.forEach(spec => landingSpecs.appendChild(spec));
    } else {
      const specs = document.getElementById('seqbpspecs');

      if (specs) {
        specs.parentNode.removeChild(specs);
        document.querySelector(selectors.rawProductSpecs).appendChild(specs);
      }
    }
  }

  _parseSpecs(el) {
    const specs = [];
    let flag = false;

    const moar = el.querySelector('.moar');

    if (moar) {
      moar.remove();
      return moar;
    }

    const children = Array.from(el.children);
    const re = /specifications?:?$/gi;

    for (let i = 0; i < children.length; i++) {
      const child = children[i];

      if (!flag) {
        const text = child.textContent.trim();
        const match = re.exec(text);

        if (match && match.length) {
          child.remove();
          flag = true;
          continue;
        }
      }

      if (flag) {
        specs.push(child);
        child.remove();
      }
    }

    return specs;
  }
}
