import initCarousel from '../helpers/carousel';
import { unitlessBreakpoints } from '../helpers/breakpoints';

export default class DynamicTestimonials {
  constructor(section) {
    this.carousel = initCarousel(section.el, {
      perPage: 1,
      type: 'loop',
      perMove: 1,
      arrows: false,
      pagination: true,
      autoplay: true,
      breakpoints: {
        [unitlessBreakpoints.sm]: {
          pagination: true,
          perPage: 1,
          padding: '25%',
        },
        [unitlessBreakpoints.md]: {
          padding: '0',
          perPage: 3,
        },
      },
    });
  }
}
