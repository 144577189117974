import { getTranslation } from '../../helpers';
import { h } from 'preact';
import { useCartContext } from '../context/CartContext';

const CartRemove = ({ item }) => {
  const { removeLineItem } = useCartContext();

  return (
    <button
      class="type-text-4 underline hover:no-underline"
      aria-label={getTranslation('cart.remove_item')}
      type="button"
      onClick={() => removeLineItem(item.key)}
    >
      {getTranslation('cart.remove')}
    </button>
  );
};

export default CartRemove;
