const CloseIcon = ({ className }) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    fill="none"
    viewBox="0 0 40 40"
    className={className}
  >
    <g clip-path="url(#a)">
      <path
        d="M9.4 9.6 20 20.2m0 0L9.4 30.8M20 20.2l10.6 10.6M20 20.2 30.6 9.6"
        stroke="currentColor"
        stroke-width="2"
      />
    </g>
    <defs>
      <clipPath id="a">
        <path fill="#fff" d="M0 0h40v40H0z" />
      </clipPath>
    </defs>
  </svg>
);

export default CloseIcon;
