import DynamicCardFeed from '../scripts/sections/DynamicCardFeed';
import DynamicFaq from '../scripts/sections/DynamicFaq';
import DynamicFeaturedProduct from '../scripts/sections/DynamicFeaturedProduct';
import DynamicLogoCarousel from '../scripts/sections/DynamicLogoCarousel';
import DynamicMediaWithText from '../scripts/sections/DynamicMediaWithText';
import DynamicProductDetails from '../scripts/sections/DynamicProductDetails';
import DynamicProductFeed from '../scripts/sections/DynamicProductFeed';
import DynamicTestimonials from '../scripts/sections/DynamicTestimonials';

// Sections
import MainAddresses from '../scripts/sections/MainAddresses';
import MainCollection from '../scripts/sections/MainCollection';
import MainFaq from '../scripts/sections/MainFaq';
import MainHeader from '../scripts/sections/MainHeader';
import MainProduct from '../scripts/sections/MainProduct';
import Sections from '../scripts/helpers/SectionManager';

// React
import mountPreact from '../scripts/preact/mountPreact';

// Init global sections
const sections = new Sections();
sections.register('main-product', section => new MainProduct(section));
sections.register('main-addresses', section => new MainAddresses(section));
sections.register(
  'main-collection-grid',
  section => new MainCollection(section),
);
sections.register('main-header', section => new MainHeader(section));
sections.register('main-faq', section => new MainFaq(section));

sections.register(
  'dynamic-product-feed',
  section => new DynamicProductFeed(section),
  { lazy: true },
);

sections.register(
  'dynamic-product-details',
  section => new DynamicProductDetails(section),
  {
    lazy: true,
  },
);

sections.register(
  'dynamic-card-feed',
  section => new DynamicCardFeed(section),
  { lazy: true },
);
sections.register(
  'dynamic-featured-product',
  section => new DynamicFeaturedProduct(section),
  {
    lazy: true,
  },
);
sections.register(
  'dynamic-media-w-text',
  section => new DynamicMediaWithText(section),
  {
    lazy: true,
  },
);
sections.register(
  'dynamic-logo-carousel',
  section => new DynamicLogoCarousel(section),
  {
    lazy: true,
  },
);
sections.register(
  'dynamic-testimonials',
  section => new DynamicTestimonials(section),
  {
    lazy: true,
  },
);
sections.register('dynamic-faq', section => new DynamicFaq(section), {
  lazy: true,
});

sections.register('main-search', section => new MainCollection(section));

// Init preact
mountPreact();
