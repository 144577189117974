import { formatMoney } from '@shopify/theme-currency';

const CartCrossSellProduct = ({ product }) => {
  if (!product) {
    return null;
  }

  const selectedVariant = product.variants[0];

  const cross_sell_image = product.images[0] ? product.images[0] : '';

  return (
    <div class="h-full pr-4">
      <div
        class="h-full w-48 overflow-hidden rounded-product-item bg-white"
        data-product-id={product.id}
      >
        <div class="p-2 sm:p-4">
          <div class="aspect-product-item">
            <a href={product.url}>
              <img
                src={cross_sell_image}
                class=" h-full w-full object-contain"
              />
            </a>
          </div>
        </div>

        <div class="flex flex-col px-2 pb-2 text-primary-1 sm:px-4 sm:pb-4">
          <div class="type-text-7 mb-2">
            {formatMoney(selectedVariant.price, theme.moneyFormat)}
          </div>

          <p class="type-heading-7 mb-1">
            <a href={product.url}>{product.title}</a>
          </p>

          {product.vendor && <p class="type-text-4">{product.vendor}</p>}
        </div>
      </div>
    </div>
  );
};

export default CartCrossSellProduct;
