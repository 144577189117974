import cn from 'classnames';
import { getTranslation } from '../../helpers';
import { h } from 'preact';
import { useCartContext } from '../context/CartContext';

import { useEffect, useState } from 'preact/hooks';

const QuantitySelector = ({ item, setError }) => {
  const { updateLineItem } = useCartContext();
  const [quantity, setQuantity] = useState(item.quantity);
  const [updatingQuantity, setUpdatingQuantity] = useState(null);

  useEffect(() => {
    if (item.quantity < updatingQuantity) {
      setError(item.quantity);
    }
    setQuantity(item.quantity);
    setUpdatingQuantity(null);
  }, [item]);

  const updateQuantity = value => {
    setUpdatingQuantity(value);
    updateLineItem(item.key, value);
  };

  const Button = ({ children, label, onClick, disabled }) => (
    <button
      class={cn(
        'size-8 border border-primary-1 focus:outline-none focus:ring-1',
        {
          'cursor-default text-grey-3': disabled,
        },
      )}
      type="button"
      aria-label={label}
      onClick={onClick}
      disabled={disabled || updatingQuantity !== null}
    >
      {children}
    </button>
  );

  return (
    <div class="inline-flex">
      <Button
        label={getTranslation('general.form.decrement')}
        onClick={() => updateQuantity(quantity - 1)}
        disabled={quantity === 1}
      >
        -
      </Button>

      <input
        class="type-text-8 hide-arrows inline-block h-8 w-10 rounded-none border border-l-0 border-r-0 border-primary-1 p-0 text-center"
        type="number"
        name="quantity"
        value={quantity}
        aria-label={getTranslation('general.form.quantity')}
        min="1"
        onInput={e => updateQuantity(e.target.value)}
      />

      <Button
        label={getTranslation('general.form.increment')}
        onClick={() => updateQuantity(quantity + 1)}
      >
        +
      </Button>
    </div>
  );
};

export default QuantitySelector;
