const SubscriptionIcon = ({ className }) => (
  <svg
    className={className}
    viewBox="0 0 20 20"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M3.3 3.3v4.2h.5m0 0a6.7 6.7 0 0 1 12.8 1.7M3.8 7.5h3.7m9.2 9.2v-4.2h-.5m0 0a6.7 6.7 0 0 1-12.8-1.7m12.8 1.7h-3.7"
      stroke="currentColor"
      stroke-linecap="square"
      stroke-linejoin="round"
    />
  </svg>
);

export default SubscriptionIcon;
