import CartItemPrice from './CartItemPrice';
import CartRemove from './CartRemove';
import QuantitySelector from '../shared-components/QuantitySelector';
import SubscriptionIcon from '../icons/SubscriptionIcon';
import cn from 'classnames';
import { formatMoney } from '@shopify/theme-currency';
import { getSizedImageUrl } from '@shopify/theme-images';
import { getTranslation } from '../../helpers';
import { h } from 'preact';
import { useState } from 'preact/hooks';

const CartItem = ({ item }) => {
  const [error, setError] = useState();

  return (
    <div class="px-4 md:px-6">
      <div class="relative flex items-center border-b border-primary-1 py-4">
        {item.featured_image && item.featured_image.url && (
          <div class="mr-4 w-16 md:w-20">
            <a class="block aspect-product-item" href={item.url}>
              <img
                class="h-full w-full object-contain"
                alt={item.featured_image.alt}
                src={
                  getSizedImageUrl(item.featured_image.url, 'medium')
                    ? getSizedImageUrl(item.featured_image.url, 'medium')
                    : item.featured_image.url
                }
              />
            </a>
          </div>
        )}

        <div class="flex-1">
          <div class="flex gap-4 md:items-center">
            <div class="flex-1">
              <a class="type-heading-6 mb-1 block" href={item.url}>
                {item.product_title}
              </a>
              <p className="type-text-4 mb-1">
                {!item.product_has_only_default_variant &&
                  item.options_with_values.map(
                    ({ name, value }, i) =>
                      name !== 'Region' && (
                        <span
                          className={cn({
                            "after:mx-2 after:content-['·']":
                              item.options_with_values.length - 1 > i,
                          })}
                        >
                          {value}
                        </span>
                      ),
                  )}
              </p>
              <div class="flex">
                <span class="type-text-8 mb-1 basis-full md:mb-0 md:flex-grow-0 md:basis-0">
                  <span class="text-primary-1">
                    {formatMoney(item.final_price, theme.moneyFormat)}
                  </span>
                  {item.compare_at_price > item.final_price && (
                    <span class="ml-2 text-grey-3 line-through">
                      {formatMoney(item.compare_at_price, item.currency)}
                    </span>
                  )}
                </span>
              </div>

              {item.selling_plan_allocation && (
                <span class="type-text-8 my-1 inline-flex items-center bg-grey-4 px-2 py-1">
                  <SubscriptionIcon className="mr-1 size-5" />
                  {item.selling_plan_allocation.selling_plan.name}
                </span>
              )}

              {item.properties &&
                Object.entries(item.properties).map(
                  ([key, val]) =>
                    val &&
                    !key.startsWith('_') && (
                      <p className="type-text-8">
                        {key}:{' '}
                        {val.includes('/uploads/') ? (
                          <a href={val}>{val.split('/').pop()}</a>
                        ) : (
                          val
                        )}
                      </p>
                    ),
                )}
            </div>

            <div class="md:flex md:flex-row-reverse md:items-start">
              <div class="mb-3 md:mb-0 md:ml-8">
                <CartItemPrice item={item} />
              </div>
              <div class="flex flex-col items-end gap-1 md:items-center md:gap-2">
                <QuantitySelector item={item} setError={setError} />
                <CartRemove item={item} />
              </div>
            </div>
          </div>

          {/* Discounts */}
          {item.line_level_discount_allocations.length > 0 && (
            <div class="mt-2 space-y-2">
              {Object.entries(item.line_level_discount_allocations).map(
                ([key, value]) => (
                  <div key={key} class="type-text-8">
                    {value.discount_application.title}
                  </div>
                ),
              )}
            </div>
          )}
        </div>

        {error && (
          <div class="type-text-8 absolute bottom-1 right-0 text-error">
            {getTranslation('cart.quantity_error', { quantity: error })}
          </div>
        )}
      </div>
    </div>
  );
};

export default CartItem;
