import { createPortal } from 'preact/compat';
import { getTranslation } from '../../helpers';
import { useCartContext } from '../context/CartContext';
import useEmptyPortal from '../hooks/useEmptyPortal';

import { h } from 'preact';

const MiniCartEl = document.getElementById('preact-mini-cart');

const MiniCart = () => {
  const { cart, toggleMiniCart } = useCartContext();
  const [emptied, initialHtml] = useEmptyPortal(MiniCartEl);

  if (!emptied) {
    return null;
  }
  return createPortal(
    <button
      class="flex items-center gap-1.5"
      aria-label={getTranslation('header.cart')}
      onClick={() => {
        toggleMiniCart(true);
      }}
    >
      <span dangerouslySetInnerHTML={{ __html: initialHtml }}></span>
      <div className="type-text-8">{cart.item_count}</div>
    </button>,
    MiniCartEl,
  );
};

export default MiniCart;
